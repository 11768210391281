<template>
	<tr>
		<td class="text-xs">
			<span v-if="order.in_house_order_no">{{
				order.in_house_order_no
			}}</span>
			<span v-else></span>
		</td>
		<td class="text-xs">{{ order.customer_name }}</td>
		<td class="text-xs">{{ order.document_number }}</td>
		<td class="text-xs">
			<span v-if="order.order_type === 'Order'">{{ $t("order") }}</span
			><span v-else>{{ $t("invoice_") }}</span>
		</td>
		<td class="text-xs">{{ order.order_date }}</td>
		<td class="text-xs">
			<p
				v-if="order.order_status === 'processing'"
				class="p-1 bg-table-yellow rounded"
			>
				{{ $t("_processing") }}
			</p>

			<p
				v-if="order.order_status === 'paid'"
				class="p-1 bg-blue-800 text-white rounded"
			>
				{{ $t("paid") }}
			</p>
			<p
				v-if="order.order_status === 'delivered'"
				class="p-1 bg-table-green rounded"
			>
				{{ $t("delivered") }}
			</p>
			<p
				v-if="order.order_status === 'cancelled'"
				class="p-1 bg-table-red rounded"
			>
				{{ $t("cancelled") }}
			</p>
			<!--			 {{ order.order_status }}-->
		</td>
		<td class="text-xs">{{ order.total_to_pay }} SEK</td>
		<td class="text-xs">
			{{ order.your_reference }}
		</td>
		<td>
			<span class="flex">
				<el-tooltip :content="$t('download')">
					<DownloadTableManager
						:data="order"
						:value="order_type"
						class="order-icon"
					/>
				</el-tooltip>
				<span
					:title="$t('orderHelpText')"
					class="ml-1 mr-1 flex justify-center align-items-center h-7 w-7 rounded-full bg-blue-900 cursor-pointer order-icon"
					v-on:click="openOrderDetail"
				>
					<img src="./../../../assets/images/icons/edit.png" alt="" />
				</span>
				<span
					:title="$t('cancelOderInvoiceHelpText')"
					class="ml-1 mr-1 flex justify-center align-items-center h-7 w-7 cursor-pointer order-icon"
					v-on:click="cancelOrderOrInvoice"
				>
					<img
						src="./../../../assets/images/icons/cancel.svg"
						alt=""
					/>
				</span>
				<span
					:title="
						order.order_type === 'invoice'
							? $t('copyInvoice')
							: $t('copyOrder')
					"
					v-on:click="createOrderOrInvoiceCopy"
					class="ml-1 mr-1 flex justify-center align-items-center h-7 w-7 cursor-pointer order-icon"
				>
					<img
						src="./../../../assets/images/icons/duplicate.svg"
						alt=""
					/>
				</span>
				<span class="ml-2 cursor-pointer order-icon">
					<el-tooltip :content="$t('note')">
						<svg
							width="17"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							@click="openModalNote(order)"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M19.4811 2.80285L16.9956 0.317329C16.782 0.113634 16.4981 0 16.2029 0C15.9078 0 15.6239 0.113634 15.4103 0.317329L13.2002 2.53415H1.34353C0.987202 2.53415 0.64547 2.6757 0.39351 2.92766C0.14155 3.17962 0 3.52135 0 3.87768V18.6565C0 19.0128 0.14155 19.3545 0.39351 19.6065C0.64547 19.8584 0.987202 20 1.34353 20H16.1223C16.4787 20 16.8204 19.8584 17.0723 19.6065C17.3243 19.3545 17.4659 19.0128 17.4659 18.6565V6.40351L19.4811 4.38822C19.6912 4.17788 19.8091 3.89278 19.8091 3.59553C19.8091 3.29829 19.6912 3.01319 19.4811 2.80285ZM9.96225 12.0262L7.14756 12.6509L7.81933 9.86309L14.2347 3.43431L16.4045 5.60411L9.96225 12.0262ZM17.13 4.8383L14.9602 2.6685L16.2029 1.42574L18.3727 3.59553L17.13 4.8383Z"
								:fill="has_note_and_not_viewed(order)"
							/>
						</svg>
					</el-tooltip>
				</span>
			</span>
		</td>
	</tr>
</template>

<script>
import DownloadTableManager from "./../../../customComponent/DownloadTableManager";
import FilterOrderComponent from "./FilterOrderComponent";

export default {
	name: "orderTable",
	props: {
		order: {
			type: Object,
		},
	},
	components: {
		FilterOrderComponent,
		Notification: () =>
			import("./../../../customComponent/NotificationAlert"),
		DownloadTableManager,
		// FilterOrderComponent,
	},
	data: function () {
		return {
			order_type: "order",
		};
	},
	methods: {
		has_note_and_not_viewed(order) {
			if (order.message === "") {
				return "#6b7280";
			}
			if (order.hasOwnProperty("is_seller_handle_note")) {
				if (
					order.is_seller_handle_note === false &&
					order.message !== ""
				) {
					return "orange";
				} else {
					return "#2CDCA7";
				}
			} else {
				return "orange";
			}
		},
		openModalNote(order) {
			window.Bus.$emit("open-modal-seller-note", order);
		},
		openOrderDetail: function () {
			window.Bus.$emit("open-edit-order", this.order);
		},
		cancelOrderOrInvoice: function () {
			let self = this;
			this.$confirm(
				`${this.$t("cancelOderInvoiceHelpText")}?`,
				`${this.$t("warning")}`,
				{
					confirmButtonText: this.$t("yes"),
					cancelButtonText: this.$t("cancel"),
					type: "warning",
					beforeClose: (action, instance, done) => {
						if (action === "confirm") {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = `${this.$t(
								"cancelling"
							)}...`;
							self.$store
								.dispatch("portal/cancelOrder", {
									id: this.order.id,
								})
								.then((res) => {
									this.order.cancelled = true;
									this.order.order_status = "cancelled";
									this.$services.helpers.notification(
										`${this.$t("cancelSuccessful")}`,
										"success",
										this
									);
									done();
									instance.confirmButtonLoading = false;
									instance.confirmButtonText = self.$t("yes");
								})
								.catch((err) => {
									done();
									instance.confirmButtonLoading = false;
									window.Bus.$emit("output-error", err);
								});
						} else {
							done();
						}
					},
				}
			)
				.then(() => {})
				.catch(() => {});
		},
		createOrderOrInvoiceCopy: function () {
			let self = this;
			let order_type =
				this.order.order_type === "invoice"
					? this.$t("invoice")
					: this.$t("order");
			this.$confirm(
				`${this.$t("createOrderOrInvoiceCopyText")} ${order_type}?`,
				`${this.$t("info")}`,
				{
					confirmButtonText: this.$t("proceed"),
					cancelButtonText: this.$t("cancel"),
					type: "info",
					beforeClose: (action, instance, done) => {
						if (action === "confirm") {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = `${self.$t(
								"proceed"
							)}...`;
							self.$store
								.dispatch("portal/createOrderInvoiceCopy", {
									id: this.order.id,
								})
								.then(() => {
									this.$services.helpers.notification(
										`${order_type} ${self.$t(
											"createSuccess"
										)}`,
										"success",
										this
									);
									done();
									instance.confirmButtonLoading = false;
									instance.confirmButtonText =
										self.$t("proceed");
								})
								.catch((err) => {
									done();
									instance.confirmButtonLoading = false;
									window.Bus.$emit("output-error", err);
								});
						} else {
							done();
						}
					},
				}
			)
				.then(() => {})
				.catch(() => {});
		},
	},
	mounted() {
		this.order_type = this.order.order_type;
	},
};
</script>

<style lang="scss" scoped>
@import "./../../../assets/variables.scss";

.custom-btn-pay {
	background-color: #f167ca !important;
	border-color: #f167ca !important;
	font-weight: 800;
}

.custom-card-section {
	box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
		0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
		0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
		0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
	border-width: 0;
	transition: all 0.2s;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(26, 54, 126, 0.125);
}

.custom-save-btn {
	background-color: #2cdca7 !important;
	border-color: #2cdca7 !important;
	font-weight: 800;
}

.override-form-field {
	border: 0;
	border-bottom: 2px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: #fff !important;
	border-radius: unset !important;
}

.bg-blue-500 {
	background: rgb(64, 138, 235);
}

.col-form-label {
	padding-top: 1rem !important;
	// font-size: 14px !important;
}

.form-group {
	margin-bottom: 0.4rem !important;
}

.form-input {
	font-size: 0.8rem !important;
	padding: 0.2rem 0.75rem !important;
}

.col-form-label {
	padding-bottom: unset !important;
	padding-top: 0.3rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

// .table td{
//     padding: 0.1rem 0.55rem !important;
// }
.form-select {
	border: 1px solid #dfe0eb !important;
	height: unset !important;
	padding: 0.175rem 1.5rem !important;
}

.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: 0.175rem 0.75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	color: $theme-secondary-color !important;
}

.tab-btn.active {
	color: #181c4c !important;
	background-color: #fff !important;
}

.table tbody tr > td {
	font-size: 13px !important;
	color: #6f6f6f !important;
	padding: 0.6rem 0.55rem !important;
}

.icon-list {
	font-size: 15px;
	position: relative;
	top: 2px;
}

.order-icon {
	height: 22px;
	width: 22px;
}
</style>
