<template>
	<div class="app-main__inner">
		<div class="container mb-3">
			<div class="show-lg-screen">
				<div class="row flex flex-col md:flex-row justify-between align-items-center mb-3 ">
					<div data-cy='order-header'>
						<div class="flex justify-between align-items-center space-x-3 mb-3">
						<span class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
								$t('orders')
							}}</span>
							<span v-on:click="openCreateOrder"
								  class="text-xs h-10 sm-center create-order-btn cursor-pointer"
							><svg width="20"
								  height="24"
								  viewBox="0 0 20 24"
								  fill="none"
								  xmlns="http://www.w3.org/2000/svg">
									<path
										d="M11.4735 0C11.7764 0 12.0093 0.247059 12.0093 0.541176V4.32941C12.0093 6.48235 13.7682 8.24706 15.8998 8.25882C16.7851 8.25882 17.484 8.27059 18.0198 8.27059C18.3809 8.27059 18.975 8.25882 19.4758 8.25882C19.767 8.25882 20 8.49412 20 8.78823V18.2471C20 21.1647 17.6587 23.5294 14.7583 23.5294H5.49796C2.46942 23.5294 0 21.0471 0 17.9882V5.30588C0 2.38824 2.35294 0 5.25335 0H11.4735ZM9.78451 9.14118C9.30693 9.14118 8.91089 9.52941 8.91089 10.0118V12.0471H6.9074C6.42982 12.0471 6.03378 12.4353 6.03378 12.9294C6.03378 13.4118 6.42982 13.8 6.9074 13.8H8.91089V15.8353C8.91089 16.3176 9.30693 16.7059 9.78451 16.7059C10.2621 16.7059 10.6465 16.3176 10.6465 15.8353V13.8H12.6616C13.1392 13.8 13.5352 13.4118 13.5352 12.9294C13.5352 12.4353 13.1392 12.0471 12.6616 12.0471H10.6465V10.0118C10.6465 9.52941 10.2621 9.14118 9.78451 9.14118ZM13.7054 1.06612C13.7054 0.559059 14.3146 0.307294 14.6629 0.673176C15.9221 1.99553 18.1224 4.30729 19.3525 5.59906C19.6926 5.95553 19.4433 6.54729 18.9529 6.54847C17.9955 6.552 16.8667 6.54847 16.0549 6.54023C14.7666 6.54023 13.7054 5.46847 13.7054 4.16729V1.06612Z"
										fill="#E8B105"/>
									</svg>
					</span>
						</div>

					</div>
					<div class="div-action order-switch">
						<el-switch
							@change="onToggleEvent"
							v-model="order_type"
							:active-text="$t('invoice_')"
							:inactive-text="$t('order')"
						>
						</el-switch>
						<div
							class="flex justify-between align-items-center uppercase border rounded-md bg-white text-xs md:text-xl">

						<span data-cy='tab1' @click="showTab(6)"
							  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-l-md"
							  :class="tab === 6 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
								$t('all')
							}}</span>
							<span data-cy='tab1' @click="showTab(1)"
								  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2 "
								  :class="tab === 1 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
									$t('delivered')
								}}</span>
							<span data-cy='tab2' @click="showTab(2)"
								  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2"
								  :class="tab === 2 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
									$t('paid')
								}}</span>
							<span data-cy='tab3' @click="showTab(3)"
								  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2 "
								  :class="tab === 3 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
									$t('processing')
								}}</span>
							<span data-cy='tab3' @click="showTab(5)"
								  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-r-md"
								  :class="tab === 5 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
									$t('cancelled')
								}}</span>
						</div>
					</div>

				</div>
			</div>
			<div class="show-sm-screen row">
				<div class="row no-gutters ">
					<div class="col-6">
						<div data-cy='order-header'>
							<div class="flex align-items-center space-x-3 mb-3">
						<span class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
								$t('orders')
							}}</span>
								<span v-on:click="openCreateOrder"
									  class="text-xs h-10 sm-center create-order-btn cursor-pointer"
								><svg width="20"
									  height="24"
									  viewBox="0 0 20 24"
									  fill="none"
									  xmlns="http://www.w3.org/2000/svg">
									<path
										d="M11.4735 0C11.7764 0 12.0093 0.247059 12.0093 0.541176V4.32941C12.0093 6.48235 13.7682 8.24706 15.8998 8.25882C16.7851 8.25882 17.484 8.27059 18.0198 8.27059C18.3809 8.27059 18.975 8.25882 19.4758 8.25882C19.767 8.25882 20 8.49412 20 8.78823V18.2471C20 21.1647 17.6587 23.5294 14.7583 23.5294H5.49796C2.46942 23.5294 0 21.0471 0 17.9882V5.30588C0 2.38824 2.35294 0 5.25335 0H11.4735ZM9.78451 9.14118C9.30693 9.14118 8.91089 9.52941 8.91089 10.0118V12.0471H6.9074C6.42982 12.0471 6.03378 12.4353 6.03378 12.9294C6.03378 13.4118 6.42982 13.8 6.9074 13.8H8.91089V15.8353C8.91089 16.3176 9.30693 16.7059 9.78451 16.7059C10.2621 16.7059 10.6465 16.3176 10.6465 15.8353V13.8H12.6616C13.1392 13.8 13.5352 13.4118 13.5352 12.9294C13.5352 12.4353 13.1392 12.0471 12.6616 12.0471H10.6465V10.0118C10.6465 9.52941 10.2621 9.14118 9.78451 9.14118ZM13.7054 1.06612C13.7054 0.559059 14.3146 0.307294 14.6629 0.673176C15.9221 1.99553 18.1224 4.30729 19.3525 5.59906C19.6926 5.95553 19.4433 6.54729 18.9529 6.54847C17.9955 6.552 16.8667 6.54847 16.0549 6.54023C14.7666 6.54023 13.7054 5.46847 13.7054 4.16729V1.06612Z"
										fill="#E8B105"/>
									</svg>
					</span>
							</div>

						</div>
					</div>
					<div class="col-6 ">
						<div class="order-switch">
							<el-switch
								@change="onToggleEvent"
								v-model="order_type"
								:active-text="$t('invoice_')"
								:inactive-text="$t('order')"
							>
							</el-switch>
						</div>

					</div>
				</div>
				<div
					class="flex justify-between align-items-center uppercase border rounded-md bg-white text-xs md:text-xl sm-order-action">

						<span data-cy='tab1' @click="showTab(6)"
							  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-l-md"
							  :class="tab === 6 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
								$t('all')
							}}</span>
					<span data-cy='tab1' @click="showTab(1)"
						  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2 "
						  :class="tab === 1 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">SLUTLEVERERADE</span>
					<span data-cy='tab2' @click="showTab(2)"
						  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2"
						  :class="tab === 2 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">SLUTBETALDA</span>
					<span data-cy='tab3' @click="showTab(3)"
						  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2 "
						  :class="tab === 3 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">ICKE LEVERERADE</span>
					<span data-cy='tab3' @click="showTab(5)"
						  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-r-md"
						  :class="tab === 5 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('cancelled')
						}}</span>
				</div>
			</div>


			<div class="row flex justify-end">
				<div class="col-md-12 mt-2">
					<span v-if="GET_ORDER_FILTER.length > 0"
						  class="flex block text-gray-400 justify-end items-center text-xs mb-2">
                <span class="mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24"
						 stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round"
						  d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"/>
                    </svg>
                </span>
                {{ $t('activeFilter') }}
            </span>
					<div class="flex flex-wrap md:space-x-4 justify-end mb-2 pr-2">
                		<span class="flex justify-between space-x-3 font-semibold text-blue-900 text-xs"
							  v-for="(item, i) in GET_ORDER_FILTER" :key="item.mode">
                    <span>
                        {{ item.mode === 'order_type' ? $t('orderType') : null }}
                    </span>
                    <span class="cursor-pointer" @click="removeItemFromFilter(item)">x</span>
                </span>
					</div>
				</div>
				<div class="col-md-12 flex justify-center md:justify-end mb-2 bg-white border rounded-md p-2">
					<!--                    <span class="mr-2 md:mb-0 cursor-pointer" @click="showFilter = !showFilter">-->
					<!--                            <span-->
					<!--								class="flex justify-between align-items-center space-x-4 bg-gray-200 border px-3 py-2 rounded-md">-->
					<!--                                <span><img src="./../../assets/images/icons/Frame.png" alt=""></span>-->
					<!--                                <span class="text-gray-500">{{ $t('filter') }}</span>-->
					<!--                            </span>-->
					<!--                        </span>-->
					<!--					<div class="absolute w-60 bg-white rounded border shadow-lg md:-ml-10 md:mt-10"-->
					<!--						 style="z-index:9999; right: 157px !important;" v-if="showFilter">-->
					<!--						<FilterOrderComponent-->
					<!--							@hideAdvanceFilter="showFilter = false"-->
					<!--							@handleFilter='handleFiltering'-->
					<!--							@clearFilter='clearFilter'-->
					<!--						/>-->
					<!--					</div>-->
					<div
						class="flex flex-col md:flex-row justify-end align-items-center space-x-4 space-y-3 md:space-y-0 w-full">
						<div class="w-full lg:w-4/12">
							<input data-cy='search-field' type="text" @input="searchInputTrigger"
								   class="flex justify-between w-full align-items-center space-x-4 focus:outline-none bg-gray-200 border px-3 py-2 rounded-md text-gray-500"
								   v-bind:placeholder="$t('search')">
						</div>
					</div>
				</div>
			</div>

			<div class="row dm-sans-font">
				<div class="w-full mt-3">
					<div class="table-responsive rounded-lg bg-white border">
						<table v-loading='loading' style="width: 100%;" id="exampledestroy" class="table table-hover">
							<thead>
							<tr>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('b2bOrderNumber')
										}}<span class="flex flex-col"><i @click="sortOrder('in_house_order_no')"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortOrder('-in_house_order_no')"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('customerName')
										}}<span class="flex flex-col"><i @click="sortOrder('customer_name')"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortOrder('-customer_name')"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('orderNumber')
										}}<span class="flex flex-col"><i @click="sortOrder('document_number')"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortOrder('-document_number')"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center"><span>{{
											$t('type')
										}}</span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{ $t('orderDate') }}<span
										class="flex flex-col"><i @click="sortOrder('order_date')"
																 class="lnr-chevron-up cursor-pointer small"></i><i
										@click="sortOrder('-order_date')"
										class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('orderStatus')
										}}<span class="flex flex-col"><i @click="sortOrderStatusAsc"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortOrderStatusDesc"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('orderSummary')
										}}<span class="flex flex-col"><i @click="sortOrder('total_to_pay')"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortOrder('-total_to_pay')"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{ $t('reference') }}<span></span>
									</div>
								</th>
								<th class="text-xs">{{ $t('actions') }}</th>
							</tr>
							</thead>
							<tbody>
							<order-table :order="order" v-for="(order, i) in GET_ORDERS.data" :key="i"/>

							</tbody>
						</table>
					</div>
					<div class="flex flex-col md:flex-row justify-between mb-4">
                        <span class="flex justify-between align-items-center space-x-5 mt-4">
                            <span class="text-base">{{ $t('show') }}</span>
                            <span data-cy='order-showing-number'>
                                <select v-model="limit" class="form-select" @change="shopResultNumber">
                                    <option value="100" selected>100</option>
                                    <option value="200">200</option>
                                    <option value="400">400</option>
                                    <option value="500">500</option>
                                </select>
                            </span>
                        </span>
						<div data-cy='order-pagination' class="pagination-block" v-if="GET_ORDERS.data">
							<!--GET_ARTICLES.total_pages--->
							<paginate
								:page-count="GET_ORDERS.total_pages"
								:click-handler="changePageNumber"
								prev-text="<"
								next-text=">"
								:container-class="'pagination space-x-2'">
							</paginate>
						</div>
					</div>
					<!-- <div class="pagination-block" v-if="GET_ORDERS.data">
						<paginate
						:page-count="GET_ORDERS.total_pages"
						:click-handler="changePageNumber"
						prev-text="<"
						next-text=">"
						:container-class="'pagination'">
						</paginate>
					</div> -->
				</div>
				<!-- <div class="col-md-1"></div> -->
			</div>
		</div>


		<Notification/>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import debounce from 'lodash.debounce'
import Paginate from 'vuejs-paginate'
import DownloadTableManager from './../../customComponent/DownloadTableManager'
import FilterOrderComponent from './components/FilterOrderComponent'
import orderTable from "./components/orderTable";

export default {
	name: 'order-page',
	components: {
		FilterOrderComponent,
		Notification: () => import('./../../customComponent/NotificationAlert'),
		paginate: Paginate,
		DownloadTableManager,
		orderTable
		// FilterOrderComponent,
	},
	data() {
		return {
			PaymentType: null,
			billing: {
				name: '',
				company_id: '',
				address: {
					address_line_one: '',
					zip_code: '',
					city: '',
					phone_mobile: '',
				},
				email: '',
				invoiceEmail: ''
			},
			Orders: [],
			history: [],
			copyHistory: [],
			parPage: 10,
			currentPage: 1,
			tab: 6,
			status: null,
			updating: false,
			filterName: '',
			paymentCardModal: false,
			showFilter: false,
			processingCard: false,
			processingInvoice: false,
			loading: true,
			order_type: false,
			limit: 10,
			tabList: {
				1: '',
				2: '',
				3: '',
				4: '',
			}
		}
	},
	watch: {
		limit: function (newVal, oldVal) {
			this.limit = newVal
			window.localStorage.setItem('ordernum', newVal)
		}
	},
	computed: {
		...mapGetters({
			GET_ORDERS: 'portal/GET_ORDERS',
			GET_ORDER_FILTER: 'portal/GET_ORDER_FILTER'
		})
	},
	mounted() {
		const num = window.localStorage.getItem('ordernum')
		if (parseInt(num) && parseInt(num) < 201) {
			this.limit = num
			this.getCompanyOrders(parseInt(num), null)
		} else {
			window.localStorage.setItem('ordernum', this.limit)
			this.getCompanyOrders(this.limit, null)
		}
		window.Bus.$on('handle-order-filter', _ => {
			this.handleOrderFiltering()
		})
		window.Bus.$on('reload-orders', _ => {
			this.getCompanyOrders(parseInt(this.limit), 'delivered')
		})
	},
	methods: {
		sortOrder: function (sortValue) {
			let page = `?limit=${this.limit}&ordering=${sortValue}`
			this.loading = true
			if (this.status !== null) {
				page = page + `&tab_status=${this.status}`
			}
			if (this.order_type === false) {
				page += '&order_type=Order'
			} else {
				page += '&order_type=invoice'
			}
			this.$store.dispatch('portal/getCompanyOrders', {url: page})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})

		},
		removeItemFromFilter: function (item) {
			this.$store.commit('portal/REMOVE_ORDER_FILTER', item)
			this.handleOrderFiltering()
		},
		searchInputTrigger: debounce(function (event) {
			if (event.target.value !== '') {
				this.filterSearch(event)
			} else {
				this.$store.commit('portal/FILTER_ORDERS_ALL', null)
			}
		}, 500),
		filterSearch(event) {
			// this.$store.commit('portal/FILTER_SEARCH_ORDER', {value: event.target.value})
			let page = `?limit=${this.limit}`
			if (this.status) {
				page = page + `&tab_status=${this.status}`
			}
			if (this.order_type === false) {
				page += '&order_type=Order'
			} else {
				page += '&order_type=invoice'
			}
			if (event.target.value !== '') {
				page += `&search=${event.target.value}`
			}
			this.$store.dispatch('portal/getCompanyOrders', {url: page})
				.then(_ => {
					//
				}).catch((err) => {
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		changePageNumber(number) {
			let page = `?page=${number}&limit=${this.limit}`
			if (this.status) {
				page = page + `&tab_status=${this.status}`
			}
			if (this.order_type === false) {
				page += '&order_type=Order'
			} else {
				page += '&order_type=invoice'
			}
			this.$store.dispatch('portal/getCompanyOrders', {url: page})
				.then(_ => {
					//
				}).catch((err) => {
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		getCompanyOrders(limit, status = null) {
			this.loading = true
			let pageUrl = `?limit=${limit}`
			if (status) {
				pageUrl = pageUrl + `&tab_status=${status}`
			}
			if (this.order_type === false) {
				pageUrl += '&order_type=Order'
			} else {
				pageUrl += '&order_type=invoice'
			}
			this.$store.dispatch('portal/getCompanyOrders', {url: pageUrl})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		handleFiltering(value) {
			let page = `?limit=${this.limit}`
			this.loading = true
			this.filterName = value
			if (this.order_type === false) {
				page += '&order_type=Order'
			} else {
				page += '&order_type=invoice'
			}
			if (this.status !== null) {
				page = page + `&tab_status=${this.status}`
			}
			this.$store.dispatch('portal/getCompanyOrders', {url: page})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		onToggleEvent: function () {
			if (parseInt(this.tab) === 6) {
				this.status = null
			}
			this.getCompanyOrders(this.limit, this.status)
		},
		handleOrderFiltering() {
			let page = `?limit=${this.limit}`
			this.loading = true
			if (this.status !== null) {
				page = page + `&tab_status=${this.status}`
			}
			if (this.order_type === false) {
				page += '&order_type=Order'
			} else {
				page += '&order_type=invoice'
			}
			this.$store.dispatch('portal/getCompanyOrders', {url: page})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		clearFilter() {
			this.loading = true
			this.showFilter = false
			this.filterName = ''
			this.getCompanyOrders(this.limit)
		},
		sortB2bOrderNumberAsc() {
			this.$store.commit('portal/SORT_B2B_ORDER_NUMBER_ASC')
		},
		sortB2bOrderNumberDesc() {
			this.$store.commit('portal/SORT_B2B_ORDER_NUMBER_DESC')
		},
		sortCustomerNameAsc() {
			this.$store.commit('portal/SORT_CUSTOMER_NAME_ASC')
		},
		sortCustomerNameDesc() {
			this.$store.commit('portal/SORT_CUSTOMER_NAME_DESC')
		},
		sortOrderNumberAsc() {
			this.$store.commit('portal/SORT_ORDER_NUMBER_ASC')
		},
		sortOrderNumberDesc() {
			this.$store.commit('portal/SORT_ORDER_NUMBER_DESC')
		},
		sortOrderDateAsc() {
			this.$store.commit('portal/SORT_ORDER_DATE_ASC')
		},
		sortOrderDateDesc() {
			this.$store.commit('portal/SORT_ORDER_DATE_DESC')
		},
		sortOrderStatusAsc() {
			this.$store.commit('portal/SORT_ORDER_STATUS_ASC')
		},
		sortOrderStatusDesc() {
			this.$store.commit('portal/SORT_ORDER_STATUS_DESC')
		},
		sortOrderSummaryAsc() {
			this.$store.commit('portal/SORT_ORDER_SUMMARY_ASC')
		},
		sortOrderSummaryDesc() {
			this.$store.commit('portal/SORT_ORDER_SUMMARY_DESC')
		},
		showTab(number) {
			this.tab = number
			if (this.tab === 1) {
				this.status = 'delivered'
				this.getCompanyOrders(this.limit, this.status)
			} else if (this.tab === 4) {
				this.status = 'unpaid'
				this.getCompanyOrders(this.limit, this.status)
			} else if (this.tab === 2) {
				this.status = 'paid'
				this.getCompanyOrders(this.limit, this.status)
			} else if (this.tab === 3) {
				this.status = 'processing'
				this.getCompanyOrders(this.limit, this.status)
			} else if (this.tab === 5) {
				this.status = 'cancelled'
				this.getCompanyOrders(this.limit, this.status)
			} else {
				this.status = null
				this.getCompanyOrders(this.limit, null)
			}

		},
		shopResultNumber(e) {
			this.limit = parseInt(e.target.value)
			this.getCompanyOrders(parseInt(e.target.value))
		},
		openModalNote(order) {
			window.Bus.$emit('open-modal-seller-note', order)

		},
		has_note_and_not_viewed(order) {
			if (order.hasOwnProperty('is_seller_handle_note')) {
				if (order.is_seller_handle_note === false) {
					return 'orange'
				} else {
					return '#2CDCA7'
				}
			} else {
				return 'orange'
			}
		},
		openCreateOrder: function () {
			window.Bus.$emit('open-create-order', {})
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.custom-btn-pay {
	background-color: #F167CA !important;
	border-color: #F167CA !important;
	font-weight: 800;
}

.custom-card-section {
	box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
	border-width: 0;
	transition: all .2s;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(26, 54, 126, 0.125);
}

.custom-save-btn {
	background-color: #2CDCA7 !important;
	border-color: #2CDCA7 !important;
	font-weight: 800;
}

.override-form-field {
	border: 0;
	border-bottom: 2px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: #fff !important;
	border-radius: unset !important;
}

.bg-blue-500 {
	background: rgb(64, 138, 235);
}

.col-form-label {
	padding-top: 1rem !important;
	// font-size: 14px !important;
}

.form-group {
	margin-bottom: 0.4rem !important;
}

.form-input {
	font-size: 0.8rem !important;
	padding: 0.2rem 0.75rem !important;
}

.col-form-label {
	padding-bottom: unset !important;
	padding-top: 0.3rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

// .table td{
//     padding: 0.1rem 0.55rem !important;
// }
.form-select {
	border: 1px solid #DFE0EB !important;
	height: unset !important;
	padding: .175rem 1.5rem !important;
}

.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	color: $theme-secondary-color !important;
}

.tab-btn.active {
	color: #181C4C !important;
	background-color: #fff !important;
}

.table tbody tr > td {
	font-size: 13px !important;
	color: #6F6F6F !important;
	padding: 0.6rem 0.55rem !important;
}

.div-action {
	display: flex;
	justify-content: space-around;
}

.create-order-btn {
	padding: 10px;
}

.show-lg-screen {
	display: block;
}

.show-sm-screen {
	display: none;
}


@media only screen and (max-width: 640px) {
	.div-action {
		display: block;
		justify-content: normal;
	}
	.sm-center {
		display: table;
		margin: auto;
	}
	.show-lg-screen {
		display: none;
	}
	.show-sm-screen {
		display: block;
	}
	.row-sm {
		flex-direction: inherit !important;
	}
	.sm-order-action {
		overflow-x: scroll !important;
		overflow-y: hidden !important;
	}
}
</style>
