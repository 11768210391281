<template>
  <div class="bg-gray-100 rounded overflow">
    <div class="flex justify-between align-items-center p-2">
      <button @click="clearDataField('all')" class="bg-white text-xs rounded border shadow-sm p-1">{{
          $t('clear')
        }}
      </button>
      <small class="text-xs">{{ $t('filter') }}</small>
      <button @click="filterDataNow" class="bg-blue-400 text-xs text-white rounded border shadow-sm p-1">{{
          $t('done')
        }}
      </button>
    </div>
    <div class="flex flex-col">
      <div>
        <div>
          <div class="p-2">
            <div class="form-group">
              <select v-model="type" class="form-select w-full">
                <option selected disabled>---</option>
                <option value='Order'>{{ $t('order') }}</option>
                <option value='invoice'>{{ $t('invoiceName') }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'FilterOrderComponent',
  data: function () {
    return {
      filter: 'type',
      type: ''
    }
  },
  computed: {
    ...mapGetters({
      GET_ORDER_FILTER: 'portal/GET_ORDER_FILTER'
    })
  },
  methods: {
    filterDataNow() {
      if (this.filter === 'type') {
        this.$store.commit('portal/SET_ORDER_ACTIVE_FILTER', {mode: 'order_type', value: this.type})
      } else {
        this.$store.commit('portal/REMOVE_ORDER_FILTER', {mode: 'order_type',})
      }
      window.Bus.$emit('handle-order-filter')
      this.$emit('hideAdvanceFilter')
    },
    clearDataField(d = null) {
      this.filter = ''
      this.$store.commit('portal/REMOVE_ORDER_FILTER', {mode: 'order_type',})
      this.$emit('clearFilter')
    }
  },
  mounted() {
    this.GET_ORDER_FILTER.map(item => {
      if (item.mode === 'order_type') {
        this.type = item.value
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import "./../../../assets/variables.scss";

.el-date-editor--daterange.el-input__inner {
  width: unset !important;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

.form-control, .form-select {
  border: 1px solid $form-border !important;
  height: unset !important;
  padding: .175rem .75rem !important;
}

.form-control:focus {
  box-shadow: unset !important;
  border: 1px solid $theme-primary-color !important;
}
</style>

